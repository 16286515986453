import AlertMessage from "common/core/alert_message";

import Styles from "./email_risk.module.scss";
import type { IdentityDetails_documentBundle_DocumentBundle_organizationTransaction_customerSigners_emailRiskSignal_reasonCodes as reasonCode } from "../index.query.graphql";

type Props = {
  elementId?: string;
  reasonCodes: reasonCode[];
  visible?: boolean;
};

export default function AdditionalEmailReasonCodes({
  elementId,
  reasonCodes,
  visible = true,
}: Props) {
  return (
    <ul
      id={elementId}
      className={Styles.additionalCodesContainer}
      style={visible ? {} : { display: "none" }}
    >
      {reasonCodes.map((reasonCode) => (
        <li key={reasonCode.id}>
          <AlertMessage kind="warning">{reasonCode.description}</AlertMessage>
        </li>
      ))}
    </ul>
  );
}
