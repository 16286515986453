import { FormattedMessage, useIntl } from "react-intl";

import { useA11y } from "common/accessibility";
import { useDocumentTitles } from "util/document_title";
import { Heading } from "common/core/typography";
import LoadingIndicator from "common/core/loading_indicator";
import { useQuery } from "util/graphql";
import CustomerProfileQuery from "common/settingsv2/sidebar_settings/profile/customer_profile_query.graphql";
import { Certificate } from "common/settingsv2/sidebar_settings/profile/proof_certificate/certificate";

import Styles from "../index.module.scss";

export default function ProofCertificate() {
  useA11y().useDocumentEntitler({
    title: useIntl().formatMessage(useDocumentTitles().settingsProfilePersonalDetails),
  });
  const { data, loading } = useQuery(CustomerProfileQuery);
  const user = data?.viewer.user;

  return (
    <>
      <div className={Styles.header}>
        <Heading textStyle="subtitle" level="h1">
          <FormattedMessage id="6a8032ed-01be-4b54-9a69-6ab74d66a6be" defaultMessage="Proof Card" />
        </Heading>
      </div>
      <div className={Styles.body}>{loading || !user ? <LoadingIndicator /> : <Certificate />}</div>
    </>
  );
}
