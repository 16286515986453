import { Navigate, Outlet, Route, Routes, useSearchParams, useLocation } from "react-router-dom";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";

import { useFeatureFlag } from "common/feature_gating";
import { SidebarV2BackButton } from "common/sidebar";
import { userFullName } from "util/user";
import { PROFILE_ROUTE } from "common/settingsv2/sidebar_settings/profile";
import { SETTINGS_PATH } from "util/routes";
import { PROFILE_OVERVIEW_ROUTE } from "common/settingsv2/sidebar_settings/profile/overview";
import { PROFILE_PERSONAL_DETAILS_ROUTE } from "common/settingsv2/sidebar_settings/profile/personal_details";
import { PROFILE_PERSONAL_PAYMENTS_ROUTE } from "common/settingsv2/sidebar_settings/profile/personal_payment";
import { TERMS_ROUTE } from "common/settingsv2/sidebar_settings/terms";
import { TermsAndConditions } from "common/settingsv2/sidebar_settings/terms/terms";
import SROnly from "common/core/screen_reader";
import { useViewer } from "util/viewer_wrapper";
// eslint-disable-next-line import/no-restricted-paths
import type { SignerRouterViewer_viewer_user as User } from "signer_portal/router/viewer_query.graphql";
import { INBOX_ROUTE } from "common/settingsv2/signer_settings";
import { Heading, Paragraph } from "common/core/typography";
import StackedLinks from "common/modals/full_page_responsive_modal/stacked_links";
import { PageFrameWithPadding } from "common/page_frame";
import { CONSUMER_CERTIFICATES, USER_SETTINGS_PRIVACY_TAB } from "constants/feature_gates";

import UserAccount from "./user_profile/user_account";
import PersonalDetails from "./user_profile/personal_details";
import Payment from "./user_profile/payment";
import UserProfile from "./user_profile";
import Privacy from "./user_profile/privacy";
import ProofCertificate from "./user_profile/proof_certificate";
import Styles from "./index.module.scss";
import NotarySettings, { NOTARY_PROFILE_ROUTE } from "../sidebar_settings/notary";
import { NOTARY_SECTION_ROUTES } from "../sidebar_settings/notary/section";
import { PROFILE_PRIVACY_ROUTE } from "../sidebar_settings/profile/privacy";
import { PROFILE_PROOF_CERTIFICATE_ROUTE } from "../sidebar_settings/profile/proof_certificate";

const USER_PROFILE_ROUTE = `${PROFILE_ROUTE}/${PROFILE_OVERVIEW_ROUTE}`;
const ABSOLUTE_USER_PROFILE_ROUTE = `${SETTINGS_PATH}/${USER_PROFILE_ROUTE}`;
const PROFILE_USER_ACCOUNT_ROUTE = "account";
export const ABSOLUTE_PROFILE_USER_ACCOUNT_ROUTE = `${SETTINGS_PATH}/${PROFILE_ROUTE}/${PROFILE_USER_ACCOUNT_ROUTE}`;
export const ABSOLUTE_PROFILE_PERSONAL_DETAILS_ROUTE = `${SETTINGS_PATH}/${PROFILE_ROUTE}/${PROFILE_PERSONAL_DETAILS_ROUTE}`;
export const ABSOLUTE_PROFILE_PERSONAL_PAYMENTS_ROUTE = `${SETTINGS_PATH}/${PROFILE_ROUTE}/${PROFILE_PERSONAL_PAYMENTS_ROUTE}`;
export const ABSOLUTE_PROFILE_PRIVACY_ROUTE = `${SETTINGS_PATH}/${PROFILE_ROUTE}/${PROFILE_PRIVACY_ROUTE}`;
export const ABSOLUTE_PROFILE_PROOF_CERTIFICATE_ROUTE = `${SETTINGS_PATH}/${PROFILE_ROUTE}/${PROFILE_PROOF_CERTIFICATE_ROUTE}`;

const LABELS = defineMessages({
  userProfile: {
    id: "394c17b4-fbf6-48cc-8046-0134e7df6150",
    defaultMessage: "User profile",
  },
  userProfileSub: {
    id: "ae5ea487-efa3-46a0-bea3-435c90d588bc",
    defaultMessage: "User details and preferences",
  },
  terms: {
    id: "d4a44c27-0d30-489e-b75b-ce5587c05f0d",
    defaultMessage: "Terms & Conditions",
  },
  termsSub: {
    id: "de3c1e20-4e56-43d6-ba9e-9b074d0c6394",
    defaultMessage: "User Terms of Service",
  },
});

function SettingsChildWrapper() {
  const { search } = useLocation();
  return (
    <PageFrameWithPadding>
      <div className={Styles.back}>
        <SidebarV2BackButton to={`${SETTINGS_PATH}${search}`}>
          <FormattedMessage
            id="c236eaf8-05e2-45ca-bec7-bd9457278be7"
            defaultMessage="Back to all settings"
          />
        </SidebarV2BackButton>
      </div>
      <Outlet />
    </PageFrameWithPadding>
  );
}

function UserProfileWrapper() {
  const { search } = useLocation();
  return (
    <div className={Styles.wrapper}>
      <div className={Styles.back}>
        <SidebarV2BackButton to={`${ABSOLUTE_USER_PROFILE_ROUTE}${search}`}>
          <FormattedMessage
            id="c236eaf8-05e2-45ca-bec7-bd9457278be7"
            defaultMessage="Back to user profile"
          />
        </SidebarV2BackButton>
      </div>
      <Outlet />
    </div>
  );
}

function AllSettings() {
  const { viewer } = useViewer();
  const intl = useIntl();
  const [searchParams] = useSearchParams();
  const { search } = useLocation();
  const isAppEmbed = searchParams.get("appEmbed") === "true";
  const user = viewer.user as User | null;
  const userName = {
    firstName: user?.firstName || "",
    lastName: user?.lastName || "",
  };

  return (
    <PageFrameWithPadding>
      <div className={Styles.back}>
        {!isAppEmbed && (
          <SidebarV2BackButton to={INBOX_ROUTE}>
            <FormattedMessage id="c236eaf8-05e2-45ca-bec7-bd9457278be7" defaultMessage="Back" />
            <SROnly>
              <FormattedMessage
                id="2b234ac4-c784-41e3-aa0d-dae5960828c1"
                defaultMessage="to dashboard"
              />
            </SROnly>
          </SidebarV2BackButton>
        )}
      </div>
      <div className={Styles.header}>
        <Heading textStyle="subtitle" level="h1">
          <FormattedMessage id="4ea828aa-6321-4f66-b762-6b3f6d5bcafc" defaultMessage="My account" />
        </Heading>
        <Paragraph>{userFullName(userName)}</Paragraph>
      </div>

      <StackedLinks
        fullWidth
        links={[
          {
            label: intl.formatMessage(LABELS.userProfile),
            sublabel: intl.formatMessage(LABELS.userProfileSub),
            path: USER_PROFILE_ROUTE,
            search,
          },
          {
            label: intl.formatMessage(LABELS.terms),
            sublabel: intl.formatMessage(LABELS.termsSub),
            path: TERMS_ROUTE,
            search,
          },
        ]}
      />
    </PageFrameWithPadding>
  );
}

export default function MobileUserSettings() {
  const userSettingsPrivacyTabEnabled = useFeatureFlag(USER_SETTINGS_PRIVACY_TAB);
  const userSettingsProofCertificateTabEnabled = useFeatureFlag(CONSUMER_CERTIFICATES);
  return (
    <Routes>
      <Route path={USER_PROFILE_ROUTE} element={<SettingsChildWrapper />}>
        <Route index element={<UserProfile />} />
      </Route>
      <Route path={PROFILE_ROUTE} element={<UserProfileWrapper />}>
        <Route path={PROFILE_USER_ACCOUNT_ROUTE} element={<UserAccount />} />
        <Route path={PROFILE_PERSONAL_DETAILS_ROUTE} element={<PersonalDetails />} />
        <Route path={PROFILE_PERSONAL_PAYMENTS_ROUTE} element={<Payment />} />
        {userSettingsPrivacyTabEnabled && (
          <Route path={PROFILE_PRIVACY_ROUTE} element={<Privacy />} />
        )}
        {userSettingsProofCertificateTabEnabled && (
          <Route path={PROFILE_PROOF_CERTIFICATE_ROUTE} element={<ProofCertificate />} />
        )}
        <Route index element={<Navigate to={PROFILE_OVERVIEW_ROUTE} replace />} />
      </Route>

      <Route path={TERMS_ROUTE} element={<SettingsChildWrapper />}>
        <Route index element={<TermsAndConditions />} />
      </Route>

      <Route path={NOTARY_PROFILE_ROUTE} element={<NotarySettings />}>
        {NOTARY_SECTION_ROUTES}
        <Route index element={<Navigate to="personal-info" replace />} />
      </Route>
      <Route index element={<AllSettings />} />
    </Routes>
  );
}
