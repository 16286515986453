import { RiskLevels } from "graphql_globals";

type SignerIdentity = {
  id: string;
  aggregateRiskLevel: RiskLevels | null;
  customerId: string;
  riskSignals:
    | {
        description: string;
      }[]
    | null;
};

type CustomerSigner = {
  signerIdentities: SignerIdentity[] | null;
};

type RiskInfo = {
  riskLevel: RiskLevels | null;
  customerSignerId: string;
  signerIdentity: SignerIdentity;
};

const riskLevelValues: Record<RiskLevels, number> = {
  // null is implicitly 0
  [RiskLevels.LOW]: 1,
  [RiskLevels.MEDIUM]: 2,
  [RiskLevels.HIGH]: 3,
};

export function findMaximumRiskInfo(customerSigners: CustomerSigner[]): RiskInfo | null {
  const flattenedSignerIdentities = customerSigners.flatMap(
    (signer) => signer.signerIdentities ?? [],
  );

  return flattenedSignerIdentities.reduce<RiskInfo | null>((previousRiskInfo, signerIdentity) => {
    const currentRiskInfo = {
      riskLevel: signerIdentity.aggregateRiskLevel,
      customerSignerId: signerIdentity.customerId,
      signerIdentity,
    };
    const previousRiskLevelValue = previousRiskInfo?.riskLevel
      ? riskLevelValues[previousRiskInfo.riskLevel]
      : 0;
    const currentRiskLevelValue = currentRiskInfo.riskLevel
      ? riskLevelValues[currentRiskInfo.riskLevel]
      : 0;

    return currentRiskLevelValue > previousRiskLevelValue ? currentRiskInfo : previousRiskInfo;
  }, null);
}
